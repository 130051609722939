$(document).on('turbolinks:load', function() {
	/**** MENU ****/
	/**
	 * Handle the click on the mobile menu button
	 */
	$('.menu-toggle').on('click', function() {
		if(!$("nav.left-menu").hasClass('opened') && !$("nav.left-menu").hasClass('closed')) {
			$("nav.left-menu").addClass('opened');
			$(this).addClass('opened');
		} else {
			$("nav.left-menu").toggleClass('opened closed');
			$(this).toggleClass('opened closed');
		}
	});
});