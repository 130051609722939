$( document ).on('turbolinks:load', function() {
    $(document).on('click', 'form .remove_fields', function(event){
        $(this).prev('input[type=hidden]').val('true');
        $(this).closest('fieldset').hide();
        return event.preventDefault();
    });

    $('form .add_fields').click(function(event) {
        var regexp, time;
        time = new Date().getTime();
        regexp = new RegExp($(this).data('id'), 'g');
        $(this).before($(this).data('fields').replace(regexp, time));
        return event.preventDefault();
    });

    $('select.civility').change(function(event) {
        var other_input = $(this).siblings('input.other_civility');
        if($(this).val() == 'any'){
            $(other_input).removeClass('hidden');
        }else{
            $(other_input).addClass('hidden');
        }
    });

    // disable form button if we have a blank field
    if( $('.tab-content .signature').length == 1 && $('.tab-content .signature').find('.blank').length > 0){
        $('form#approval').find('input[type=submit]').prop('disabled', true);
        $('form#ready_submit').find('input[type=submit]#record').prop('disabled', true);
    }

    if( $('.tab-content .signature').length == 1 &&
        ($('#accountability_report_signature').val() == '' && $('#file_attached').length == 0)
        ){
        $('form#ready_submit').find('input[type=submit]#record').prop('disabled', true);
        $('form#ready_submit').find('.o2_file_field').addClass('blank_other');
    }

    if( $('.tab-content .signature').length == 1 ){
        $('#accountability_report_signature').change(function(event) {
            if($('#accountability_report_signature').val() != ''){
                $('form#ready_submit').find('.o2_file_field').removeClass('blank_other');
                $('form#ready_submit').find('input[type=submit]#record').prop('disabled', false);
            }else{
                $('form#ready_submit').find('.o2_file_field').addClass('blank_other');
                $('form#ready_submit').find('input[type=submit]#record').prop('disabled', true);
            }
        });
    }

    if( $('form.edit_user').length > 0 ){
        schools = $('#user_school_id').html();
        selected_service_center = $('select#service_center :selected').val();
        options = $(schools).filter("optgroup[label='"+selected_service_center+"']").html();
        $('#user_school_id').html(options);

        $('select#service_center').change(function(event){
            selected_service_center = $('select#service_center :selected').val();
            options = $(schools).filter("optgroup[label='"+selected_service_center+"']").html();
            if( options ){
                $('#user_school_id').html(options);
            } else {
                $('#user_school_id').empty();
            }
        });

        $('select#user_school_level').change(function(event){
            role = $('select#user_role').val();
            level_selected = $(this).val();
            $('div.school').addClass('hidden');
            $('div.school select').attr('disabled', 'disabled');
            if(role != 'jury_es'){
                $('div.school.'+level_selected).removeClass('hidden');
                $('div.school.'+level_selected+' select').removeAttr('disabled');
            }
        })

        $('select#user_role').change(function(event){
            role_selected = $(this).val();
            $('div.field.jury_es.jury_univ').addClass('hidden');
            $('div.field.jury_es.jury_univ select').attr('disabled', 'disabled');
            if(role_selected == 'jury_es' || role_selected == 'jury_univ') {
                $('div.field.jury_es.jury_univ').removeClass('hidden');
                $('div.field.jury_es.jury_univ select').removeAttr('disabled');
            }
            if(role_selected == 'jury_es') {
                $('div.region_jury_es').removeClass('hidden');
                $('div.region_jury_es select').removeAttr('disabled');
                $('div.school').addClass('hidden');
                $('div.school select').attr('disabled', 'disabled');
            }else{
                $('div.region_jury_es').addClass('hidden');
                $('div.region_jury_es select').attr('disabled', 'disabled');
                level_selected = $('select#user_school_level').val();
                $('div.school.'+level_selected).removeClass('hidden');
                $('div.school.'+level_selected+' select').removeAttr('disabled');
            }

        })
    }

    $('input[type="file"]').change(function(e){
        var filesName = [];
        files = e.target.files;
        for(var i = 0; i < files.length; i++){
            filesName.push(files[i].name);
        }

        $(this).parent().find('div.selected_files').html(filesName.join(', '));
    });

    $('#funding_request_signature, #accountability_report_signature, #funding_request_photos, #accountability_report_photos, #accountability_report_video').change(function(e){
        $(this).parents('form').find('input[name=next_step]:hidden').remove();
        $(this).parents('form').submit();
    });

    if( $('div.welcome').length == 1 ){
        window.scrollTo(0, 0);
    }

    $('.know_how input[type=checkbox]').change(function(e){
        if($('.know_how input[type=checkbox]:checked').length == 0){
            errorMessage ='Au moins une case a cocher doit être sélectionné.';
            $(this)[0].setCustomValidity(errorMessage);
            $(this)[0].reportValidity();
        }else{
            $checkboxes = $('.know_how input[type=checkbox]')
            for (let i = 0; i < $checkboxes.length; i++) {
                $checkboxes[i].setCustomValidity('');
                $checkboxes[i].reportValidity();
            }
        }
    });

    $('.know_how_to_be input[type=checkbox]').change(function(e){
        if($('.know_how_to_be input[type=checkbox]:checked').length == 0){
            errorMessage ='Au moins une case a cocher doit être sélectionné.';
            $(this)[0].setCustomValidity(errorMessage);
            $(this)[0].reportValidity();
        }else{
            $checkboxes = $('.know_how_to_be input[type=checkbox]')
            for (let i = 0; i < $checkboxes.length; i++) {
                $checkboxes[i].setCustomValidity('');
                $checkboxes[i].reportValidity();
            }
        }
    });

    if($('.jury_selection').length == 1){
        if($('select.choice option[value="1"]').filter(':selected').length !== 1){
            console.log('more then one selected');
            $('#es_submit').attr('disabled', 'disabled');
            $('#es_submit').addClass('disabled');
        }
    }
});
